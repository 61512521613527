/* Geral Inicio */

.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

@font-face {
  font-family: "UrbanistRegular";
  src: local("UrbanistRegular"),
   url("./fonts/Urbanist/Urbanist-Regular.ttf") format("truetype");
  font-weight: normal;
  }

.font-face-gm {
  font-family: "UrbanistRegular";
}

.textTitle {
  color: #1B55EC;
  font-size: 40px;
  font-weight: bold;
}

.textTitleMedium {
  font-family: "UrbanistRegular";
  color: #1B55EC;
  font-size: 30px;
  font-weight: bold;
}

.textTitleSmall {
  font-family: "UrbanistRegular";
  color: #1B55EC;
  font-size: 20px;
  font-weight: bold;
}

.inivisbleButton {
  background:transparent;
    border:none;
    outline:none;
    display:block;
    cursor:pointer;
}

.inivisbleButtonText {
  color: #1B55EC;
  font-size: 20px;
  font-weight: 600;
}

.buttonCenter {
    text-align: center;
    margin: auto,
}


/* Geral Fim */

/* SideBar */

.sideBar {
  position: -webkit-sticky;
  position: sticky !important;
  top: 0;
}

/* Side Bar */

/* Atletas Inicio */

#atletasTitulo {
  padding-left: 3rem;
  padding-top: 3rem;
}

/* Atletas Fim */


/* Login*/
.App {
  background-color: white;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}
/*  Login */

/* Excel to json */

.custom-classname {
  align-items: flex-end;
  text-align: end;
  color: #1B55EC !important;
  background-color: transparent !important;
}

/*  */

.asd_center {
  vertical-align: middle
}

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
  background-color: #1B55EC !important;
}

/* Oval number */

.oval_container {
  width: 35px;
  height: 35px;
  background-color: #1B55EC;
  color: white;
  border-radius: 50px;
  text-align: center;
  padding-top: 4px;
}

.oval_container_disable {
  width: 35px;
  height: 35px;
  background-color: white;
  color: #1B55EC;
  border-radius: 50px;
  text-align: center;
  padding-top: 4px;
}

/* Oval number */



/* Formulario  */



.center {

  margin: 0;
  position: absolute;
  left: 50%;
   -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); 
}


.title {
  color: #1B55EC;
  font-size: 40px;
  padding-top: 20px;
  font-weight: bold !important;
}

.subtitle {
  color: #222;
  font-size: 30px;
  padding-bottom: 20px;
}

/* FORM */

.inputfile + label {
  border-radius: 6px;
  padding: 8px;
  font-size: 1em;
  font-weight: 400;
  color: white;
  background-color: #1B55EC;
  display: inline-block;
}

.inputfile {
width: 0.1px;
height: 0.1px;
opacity: 0;
overflow: hidden;
position: absolute;
z-index: -1;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: #1b98ec;
}

.label {
  color: #1B55EC;
}


/* Place holder */
.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: gray !important;
  opacity: 0.5 !important; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: red;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
  color: red;
}

/* Disable arrows iterartors on iput numbers */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

/* Firefox */
input[type=number] {
-moz-appearance: textfield;
}

.numeroDoJogador {
  max-height: 30px;
  max-width: 60px;
  margin-right: 20px;
}

/* END FORM */

@media only screen and (max-width: 600px) {
  .title {
      color: #1B55EC;
      font-size: 30px;
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
      font-weight: bold !important;
  }
  
  .subtitle {
      color: #222;
      font-size: 24px;
      font-weight: 600;
      padding-bottom: 20px;
      padding-top: 20px;
  }
  
}
